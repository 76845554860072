<template>
    <CampaignMap />
</template>

<script>
import CampaignMap from '@/components/map/CampaignMap'

export default {
    components: {
        CampaignMap
    }
}
</script>