<template>
	<!-- Map View -->
	<CampaignMapView/>
</template>

<script>
import CampaignMapView from './CampaignMapView.vue'

export default {
components: {
    CampaignMapView,
},
    data: () => ({
    }),
    methods: {

	}
}

</script>
<style scoped>

</style>